<div class="main-logout-container" *ngIf="value == 'logout'">
    <h2 class="logout-header">Logout?</h2>
    <hr>
    <p class="logout-title">Are you sure you want to logout?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button color="primary" (click)="logout()">Yes, Logout</button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'singleApprove'">
    <h2 class="logout-header">Approve?</h2>
    <hr>
    <p class="logout-title">Are you sure to approve this onboarding request?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="singleApprove()">Yes, Approve</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'singleReject'">
    <h2 class="logout-header">Reject?</h2>
    <hr>
    <p class="logout-title">Are you sure to reject this onboarding request?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="singleReject()">Yes, Reject</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'allApprove'">
    <h2 class="logout-header">Approve?</h2>
    <hr>
    <p class="logout-title">Are you sure to approve all onboarding requests?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="approveAll()">Yes, Approve</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'allReject'">
    <h2 class="logout-header">Reject ?</h2>
    <hr>
    <p class="logout-title">Are you sure to reject all onboarding requests?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="rejectAll()">Yes, Reject</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'updateRole'">
    <h2 class="logout-header">Update Role?</h2>
    <hr>
    <p class="logout-title line-height">This staff role is created by a partner.<br>Are you sure want to change the
        partner?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="updateRole()">Yes</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'generateUrl'">
    <h2 class="url-header">Stylist Onboarding Link</h2>
    <hr>
    <div class="url-copy-container">
        <span>{{ url }}</span>
        <mat-icon class="copy-icon" title="Copy" (click)="copyUrl()" svgIcon="copy"></mat-icon>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'deleteCard'">
    <h2 class="logout-header">Delete Card?</h2>
    <hr>
    <p class="logout-title line-height">Are you sure want to delete your card details?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="deleteCard()">Yes</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<!-- <div class="main-logout-container" *ngIf="value == 'createFeatureCard'">
    <p class="logout-title line-height">You have unsaved changes for features. <br>To keep the changes, go to Features and click 'Save.'<br> Otherwise, click 'Yes' to create without saving.</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">No</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="createPlan()">Yes</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div> -->

<div class="main-logout-container" *ngIf="value == 'editFeatureCard'||value == 'createFeatureCard'">
    <!-- <p class="logout-title line-height">You have unsaved changes for features. If you want to save,<br> please click
        "No" and save your changes.<br> If not, click "Yes" to continue further.</p> -->
        <p class="logout-title line-height">You have unsaved changes for features. <br>To keep the changes, go to Features and click 'Save.'<br> Otherwise, click 'Yes' to {{value == 'editFeatureCard'?'update':'create'}} without saving.</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">No</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="value == 'editFeatureCard'?updatePlan(null):createPlan()">Yes, {{value == 'editFeatureCard'?'Update':'Create'}}</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'publishPlan'">
    <h2 class="logout-header">Publish {{currentPlan}} New Version?</h2>
    <hr>
    <!-- <p class="logout-title">Publishing this new version of the <b>{{currentPlan}}</b><br> will deactivate the current active version. <br>If you click 'No' publishing will be turned off.<br>Do you wish to proceed?</p> -->
    <p class="logout-title" *ngIf="partnerCount != 0">Publishing this new version of the <b>{{currentPlan}}</b><br> will deactivate the current active version.<br>The current version has <b>{{partnerCount}}</b> subscribers. <br>Clicking 'Yes' will activate the new version and map subscribers<br> to it from the next billing cycle.<br> Choosing 'No' will keep the current version active.<br> Do you wish to proceed?</p>
    <p class="logout-title" *ngIf="partnerCount == 0">Publishing this new version of the <b>{{currentPlan}}</b><br> will deactivate the current active version.<br>Clicking 'Yes' will activate the new version and map subscribers<br> to it from the next billing cycle.<br> Choosing 'No' will keep the current version active.<br> Do you wish to proceed?</p>
    <div class="logout-button-div"> 
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="updatePlan(false)">No</button>
        <button class="logout-button" mat-flat-button color="primary" (click)="updatePlan(true)">Yes, Publish</button>
    </div>
</div>
