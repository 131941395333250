import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { UserData } from '../../models/storage/user-data';
import { StorageService } from '../../store/storage/storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthBusinessService {

  constructor(private authService: AuthService,
    private storageService: StorageService) { }

  forgotPassword(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var actionCodeSettings = {
        url: window.location.origin + "/#/auth/login"
      };
      this.authService.forgotPassword(email, actionCodeSettings)
        .then(() => {
          resolve('Password reset email sent, check your inbox.');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async resetPassword(code: any, password: any) {
   return new Promise((resolve, reject) => {
    this.authService.resetPassword(code, password).then(() => {
      resolve('Password update successfully.');
    }).catch((error) => {
      reject(error);
    });
   })
  }

  signIn(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.authService.signIn(email, password).then((res: any) => {
        res.user.getIdToken().then((token: string) => {
          const retrievedUserData: UserData | null = this.storageService.getUserData();

          if (!retrievedUserData) {
            this.storageService.setUserData({
              ...retrievedUserData,
              "token": token
            });
            resolve(res);
          } else {            
            this.storageService.setUserData({
              ...retrievedUserData,
              "token": token
            });
            resolve(res);
          }
        });
        resolve(res);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }
  signUp(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.authService.signUp(email, password).then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  googleLogin(loginFrom: any) {
    return new Promise((resolve, reject) => {
      this.authService.googleLogin(loginFrom).then((result) => {

        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  faceBookLogin(loginFrom: any) {
    return new Promise((resolve, reject) => {
      this.authService.faceBookLogin(loginFrom).then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  appleLogin(loginFrom: any) {
    return new Promise((resolve, reject) => {
      this.authService.appleLogin(loginFrom).then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  updateEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.authService.updateEmail(email).then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  deleteUser() {
    return new Promise((resolve, reject) => {
      this.authService.deleteUser().then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  signOut() {
    return new Promise((resolve, reject) => {
      this.authService.signOut().then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  uploadProfileImage(filePath: string, file: any) {
    return new Promise((resolve, reject) => {
      try {
        this.authService.uploadProfileImage(filePath, file)
          .then((downloadUrl) => {
            downloadUrl.subscribe({
              next: (response: any) => {
                resolve(response);
              },
              error: (error: any) => {
                reject(error)
              }
            });
          })
          .catch((error) => {
            reject(error);
          });
      }
      catch (error) {
        reject(error);
      }
    })
  }
}
