import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatMenuTrigger } from "@angular/material/menu";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  mergeMap,
  takeUntil,
} from "rxjs";
import { UserFacadeService } from "src/app/facades/user/user-facade.service";
import { LogoutComponent } from "../../auth-container/logout/logout.component";
import { SharedService } from "src/app/core/services/shared/shared.service";
import { MatSidenav } from "@angular/material/sidenav";
import { NavService } from "src/app/core/utilities/navigation/nav.service";
// import { MenuList } from "src/app/core/store/permission/menu-list";
import { Location } from "@angular/common";
import { CommonHelperService } from "src/app/core/utilities/common-helper/common-helper.service";
import { UserFeedbackState } from "src/app/core/utilities/user-feedback-state/user-feedback-state";
import { SubscriptionFacadesService } from "src/app/facades/subscription/subscription-facades.service";
import { PaymentComponent } from "../../partner-subscription-container/payment/payment.component";
import { EncryptDecryptService } from "src/app/core/store/encrypt/encrypt-decrypt.service";
import { StorageService } from "src/app/core/store/storage/storage.service";
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {
  private destroy$ = new Subject();
  pageTitle!: string;
  userId: number;
  profileImage: string = "";
  imgPic: boolean = false;
  showLegend: boolean = false;
  changeIndex: number;

  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
  @ViewChild("sidenav1") sidenav1: ElementRef;
  sidenavSmall: boolean = false;
  showServiceMenu: boolean = false;
  darkMode: boolean;
  isServiceShowing = false;
  isSettingsShowing = false;
  isServiceExpanded = true;
  isSettingsExpanded = true;
  showSettingsMenu: boolean = false;
  // accessList = MenuList;
  accessList = [];
  // Use MenuList array directly bcz screen data not correctly comes from the menu-list.ts
  listMenu = [
    {
      displayName: "Dashboard",
      iconName: "dashboard",
      route: "/dashboard",
    },
    {
      displayName: "Partner Management",
      iconName: "profile-light",
      route: "/partners",
    },
    {
      displayName: "Location Management",
      iconName: "location",
      route: "/locations",
    },
    {
      displayName: "Onboarding Approval",
      iconName: "onboard",
      route: "/onboarding",
    },
    {
      displayName: "Stylist Management",
      iconName: "users",
      route: "/stylists",
    },
    {
      displayName: "Service Management",
      iconName: "work-light",
      route: "/services",
      children: [
        {
          displayName: "Service Categories",
          iconName: "work-light",
          route: "/services/service-category",
        },
        {
          displayName: "Service",
          iconName: "work-light",
          route: "/services/service-name",
        },
        {
          displayName: "Stylist's Services",
          iconName: "work-light",
          route: "/services/service",
        },
      ],
    },
    {
      displayName: "Subscription Management",
      iconName: "bookmark",
      route: "/subscription",
      children: [
        {
          displayName: "Plans",
          iconName: "graph-light",
          route: "/subscription/plans",
        },
        {
          displayName: "Features",
          iconName: "star-light",
          route: "/subscription/features",
        },
        {
          displayName: "Offers",
          iconName: "discount-light",
          route: "/services/offers",
        },
      ],
    },
    {
      displayName: "Appointment Management",
      iconName: "appointment",
      route: "/appointments",
    },
    {
      displayName: "Customer Management",
      iconName: "customers",
      route: "/customers",
    },
    {
      displayName: "Reports",
      iconName: "report",
      route: "/reports",
      children: [
        {
          displayName: "SMS Report",
          iconName: "active-light",
          route: "/reports",
        },
      ],
    },
    {
      displayName: 'My Subscription',
      iconName: "graph-light",
      route: '/partner-subscription',
      children: [
          {
              displayName: 'Subscribe & Upgrade',
              iconName: "graph-light",
              route: '/partner-subscription/view-plan',
          },
          {
              displayName: 'My Plan',
              iconName: "graph-light",
              route: '/partner-subscription/plan-details',
          },
          {
              displayName: "Billing",
              iconName: 'report',
              route: '/partner-subscription/billing-details',

          }
      ]
    },
    {
      displayName: "Settings",
      iconName: "setting",
      route: "/settings",
      children: [
        {
          displayName: "Profile",
          iconName: "profile-light",
          route: "/settings",
        },
        {
          displayName: "Staff",
          iconName: "users",
          route: "/settings/staff",
        },
        {
          displayName: "Staff Roles",
          iconName: "onboard",
          route: "/settings/staff-role",
        },
      ],
    },
  ];
  hideTitle: boolean;
  userFeedback = new UserFeedbackState();
  @ViewChild(MatMenuTrigger) legends: MatMenuTrigger;
  authId: string;
  typeId: any;
  subscribe: any;
  alwaysOpen = true;
  private sideNavToggleValue = new BehaviorSubject<any>({});
  sideNavToggleValue$: Observable<any> = this.sideNavToggleValue.asObservable();
  isExpanded: boolean = true;
  subscriptionMapped: any;
  clientId: number;
  intervalId: NodeJS.Timer;
  countryId: any;
  partnerId: any;
  subExpired: any;
  amountPaid: any;
  recordStatus: boolean;
  trialPeriod: any;
  trialDays: any;
  paymentStatus: any;
  gracePeriod: any;
  statusList: any;
  clientStaffId: number;

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private bottom: MatBottomSheet,
    private sharedService: SharedService,
    private commonHelper: CommonHelperService,
    private navService: NavService,
    private subscriptionFacade: SubscriptionFacadesService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private encryptDecrypt: EncryptDecryptService,
    private storageService: StorageService
  ) // private paymentComponent: PaymentComponent
  {
    const retrievedUserData = this.storageService.getUserData();
    this.darkMode = this.commonHelper.isDarkEnabled();
    this.setMenuByRoleAccess();
    if (retrievedUserData) {
      this.authId = retrievedUserData.authId;
      this.typeId = retrievedUserData.typeId;
      // this.subscriptionMapped = localStorage.getItem("subscriptionMapped");
      // this.subExpired = JSON.parse(localStorage.getItem("subscriptionExpired"));
      // this.trialPeriod = JSON.parse(localStorage.getItem("trialPeriod"));
      // this.paymentStatus = JSON.parse(localStorage.getItem("paymentStatus"));
      // this.gracePeriod = JSON.parse(localStorage.getItem('gracePeriod'));
      // this.trialDays = localStorage.getItem("trialDays");
      if (this.typeId == 2) {
        this.clientStaffId = retrievedUserData.clientStaffId;
      } else {
        this.partnerId = retrievedUserData.clientId;
        this.clientStaffId = retrievedUserData.clientStaffId;
        this.getFeatureAccess();
      }
    }
    this.setPageTitle();
    this.getUserDetails();
  }


  ngAfterViewInit() {
    this.navService.sidenav = this.sidenav;
  }

  sidenavClick() {
    this.isExpanded = !this.isExpanded;
    this.sideNavToggleValue.next(this.isExpanded);
  }
  mouseenter() {
    if (!this.isServiceExpanded) {
      this.isServiceShowing = true;
    }
    if (!this.isSettingsExpanded) {
      this.isSettingsShowing = true;
    }
  }

  mouseleave() {
    if (!this.isServiceExpanded) {
      this.isServiceShowing = false;
    }
    if (!this.isSettingsExpanded) {
      this.isSettingsShowing = false;
    }
  }
  getUserDetails() {
    try {
      let obj = {
        AuthId: this.authId,
        UserTypeId: "",
      };
      this.userFacadeService.getUserByAuth(obj).subscribe({
        next: (response: any) => {
          this.profileImage = response.images;
          // this.subscribe = response.isSubscriptionMapped;
          this.countryId = response.clientCountryId;
          this.recordStatus = response.recordStatus;
          const retrieveData = this.storageService.getUserData();
          this.storageService.setUserData({
            ...retrieveData,
            "locationId": response.locationId,
            "clientCountryId": response.clientCountryId
          });
          // localStorage.setItem(
          //   "subscriptionMapped",
          //   response.isSubscriptionMapped
          // );
          if (this.profileImage == "" || this.profileImage == null) {
            this.imgPic = false;
          } else {
            this.imgPic = true;
          }
          this.handleSessionTimeout();
        },
        error: (error) => {
          this.imgPic = false;
        },
      });
    } catch (error: any) {
      this.imgPic = false;
    }
  }

  getFeatureAccess() {
    this.userFeedback.startLoading();
    let apiCall = false;
    let clientId = 0;
    if(this.typeId !=2){
      if(this.partnerId != 0){
        clientId = this.partnerId;
        apiCall = true;
      }
    }else{
      if(this.clientStaffId != 0){
        clientId = this.clientStaffId;
        apiCall = true;
      }
    }
    if(apiCall == true){
      try {
        let data = {
          clientId: clientId,
        };
        this.subscriptionFacade.getFeatureAccess(data).subscribe({
          next: (res: any) => {      
            // this.statusList=res;
            this.subExpired = res.subscriptionExpired;
            this.gracePeriod = res.gracePeriod;
            this.trialPeriod = res.isTrialPeriod;
            this.paymentStatus = res.isPaymentStatus;
            this.trialDays = res.trialPeriodDaysLeft;
            this.subscribe = res.isSubscriptionMapped;
            this.cdr.detectChanges();
              const retrieveData = this.storageService.getUserData();
            this.storageService.setUserData({
              ...retrieveData,
              "isCalendarAccess": res.isCalendarAccess,
              "IsSubscriptionMapped": res.isSubscriptionMapped,
              "isDoubleBookingAccess": res.isDoubleBookingAccess,
              "isRecurringAccess": res.isRecurringAccess,
              "isReportsAccess": res.isReportsAccess,
              "notesAccess": res.notesAccess,
              "onlineBookAccess": res.onlineBookAccess,
              "subscriptionExpired": res.subscriptionExpired,
              "paymentStatus": res.isPaymentStatus,
              "gracePeriod": res.gracePeriod,
              "trialPeriod": res.isTrialPeriod,
            });
            this.userFeedback.stopLoading();
          },
          error: (error) => {
            //this.showError(error);
            this.userFeedback.stopLoading();
          },
        });
      } catch (error) {
        //this.showError(error);
        this.userFeedback.stopLoading();
      }
    }
  }

  handleSessionTimeout() {
    if (this.recordStatus == false) {
      localStorage.clear();
      this.router.navigate(["/auth/login"]);
    }
  }

  setPageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let parentRoute = this.activatedRoute.firstChild;
          let route = this.activatedRoute.firstChild?.firstChild;
          parentRoute.data.subscribe((dat) => {
            const parentPage = dat["pageTitle"];
            route.data.subscribe((d) => {
              if (parentPage == d["pageTitle"]) {
                this.hideTitle = true;
              } else if (
                d["pageTitle"] == "Service Categories" ||
                d["pageTitle"] == "Service" ||
                d["pageTitle"] == "Stylist Services" ||
                d["pageTitle"] == "Staff" ||
                d["pageTitle"] == "Staff Roles"
              ) {
                this.hideTitle = true;
              } else if (
                d["pageTitle"] == "Plans" ||
                d["pageTitle"] == "Features" ||
                d["pageTitle"] == "Offers"
              ) {
                this.hideTitle = true;
              } else if (d["pageTitle"] == "SMS Report") {
                this.hideTitle = true;
              } else {
                this.hideTitle = false;
              }
            });
          });
          if (route?.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route: any) => route.data)
      )
      .subscribe((data: any) => {
        let findIndex: any = this.activatedRoute.routeConfig?.children;
        this.pageTitle = data.pageTitle;
        findIndex.forEach((e: any, i: any) => {
          if (e.data?.pageTitle == this.pageTitle) {
            // this.breadcrumbClick(this.pageTitle)
          } else {
            return;
          }
        });
      });
  }
  ngOnInit(): void {
    this.sharedService.variableReferencesViewEvents$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data == true) {
       this.getFeatureAccess();
        // this.paymentStatus = true;
        // this.subExpired = false;

        // this.amountPaid = JSON.parse(localStorage.getItem('amountPaid'));
        // this.paymentStatus = JSON.parse(localStorage.getItem('paymentStatus'));
        // this.subExpired = JSON.parse(localStorage.getItem('subscriptionExpired'))
        // this.trialPeriod = JSON.parse(localStorage.getItem('trialPeriod'));
        // this.getFeatureAccess();
        // if (this.amountPaid == true) {
        //         this.router.navigate(["/partner-subscription/plan-details"]);
        //       } 
        //      setTimeout(() => {
        //   this.getUserDetails();
        //   this.getFeatureAccess();
        //   this.cdr.detectChanges();

        // }, 1500);
      }
    });
    this.sharedService.getUpdateObservable().subscribe(() => {
      this.getUserDetails();
    });
  }

  breadcrumbClick(pageTitle) {
    switch (pageTitle) {
      case "Dashboard":
        this.routeChange("/dashboard");
        break;
      case "Partner Management":
        this.routeChange("/partners");
        break;
      case "Location Management":
        this.routeChange("/locations");
        break;
      case "Onboarding Approval":
        this.routeChange("/onboarding");
        break;
      case "Stylist Management":
        this.routeChange("/stylists");
        break;
      case "Service Management":
        // this.pageTitle = 'Service Category';
        // this.router.navigate(['/services/service-category']);
        break;
      case "Service Categories":
        this.routeChange("/services/service-category");
        break;
      case "Service":
        this.routeChange("/services/service-name");
        break;
      case "Stylist Services":
        this.routeChange("/services/service");
        break;
      case "Subscription Management":
        break;
      case "Plans":
        this.routeChange("/subscription/plans");
        break;
      case "Features":
        this.routeChange("subscription/features");
        break;
      case "Offers":
        this.routeChange("/subscription/offers");
        break;
      case "Appointment Management":
        this.routeChange("/appointments");
        break;
      case "Customer Management":
        this.routeChange("/customers");
        break;
      case "Reports":
        // this.pageTitle = "Profile"
        // this.router.navigate(['/settings']);
        break;
      case "SMS Report":
        this.routeChange("/reports/smsreport");
        break;
      case "Settings":
        // this.pageTitle = "Profile"
        // this.router.navigate(['/settings']);
        break;
      case "Profile":
        this.routeChange("/settings");
        break;
      case "Staff":
        this.routeChange("/settings/staff");
        break;
      case "Staff Roles":
        this.routeChange("/settings/staff-role");
        break;
    }
  }

  routeChange(url) {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  onTabClick(event) {
    // Get the label of the clicked tab.
    const clickedTabLabel = event.target.textContent.trim();
    if (clickedTabLabel != "") {
      this.breadcrumbClick(clickedTabLabel);
    }
  }
  logout() {
    const config: MatBottomSheetConfig = {
      hasBackdrop: true, // Enable backdrop
    };

    const bottomSheetRef: MatBottomSheetRef = this.bottom.open(
      LogoutComponent,
      {
        panelClass: "custom-style",
        data: {
          value: "logout",
        },
        ...config,
      }
    );

    bottomSheetRef.afterOpened().subscribe(() => {
      // Listen for backdrop click event and close the bottom sheet
      const backdrop = document.querySelector(".cdk-overlay-backdrop");
      if (backdrop) {
        backdrop.addEventListener("click", () => {
          bottomSheetRef.dismiss();
        });
      }
    });
  }
  //for navigating to edit profile
  viewProfile() {
    // this.router.navigate(["/settings"]).then(() => window.location.reload());
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['settings']);
    });
  }
  selectMenu(event: any) {
    if (event.target.title) {
      this.pageTitle = event.target.title;
    }
    this.breadcrumbClick(this.pageTitle);
  }
  compareObjects(obj1: any, obj2: any): boolean {
    if (obj1.displayName != obj2.screenName) {
      return false;
    }
    return true; // Return true if objects are equal, false otherwise
  }

  // setMenuByRoleAccess() {
  //   try {
  //     this.userFacadeService.setMenuByRoleAccess().subscribe({
  //       next: (res) => {
  //         this.accessList.forEach((data,di) => {
  //           if(data.children && data.children.length>0){
  //            data.children.forEach((ce, ci) => {

  //             const cii = res[0]['accesss'].findIndex((item) => this.compareObjects(ce,item));
  //                if (cii === -1){
  //             this.accessList[di].children.splice(ci,1)
  //           }
  //         });
  //       }
  //           const foundObjectIndex = res[0]['accesss'].findIndex((item) => this.compareObjects(data, item));
  //           if (foundObjectIndex === -1) {
  //             this.accessList.splice(this.accessList.indexOf(data), 1);
  //           }
  //         });
  //       }, error(err) {

  //       },
  //     })
  //   } catch (error) {

  //   }
  // }

  setMenuByRoleAccess() {
    try {
      this.userFeedback.startLoading();
      this.accessList = this.listMenu;
      this.userFacadeService.setMenuByRoleAccess().subscribe({
        next: (res) => {
          // Check if all items in res have both read and write as false
          const allFalse = res[0]["accesss"].every(
            (item) => !item.read && !item.write
          );

          // If all items have both read and write as false, hide the side menu
          if (allFalse) {
            this.accessList = [];
            return;
          }

          // Filter out screens with both read and write false
          this.accessList = this.accessList.filter((data) => {
            // Check if current item exists in res array
            const foundObject = res[0]["accesss"].find((item) =>
              this.compareObjects(data, item)
            );
            if (!foundObject || (!foundObject.read && !foundObject.write)) {
              return false; // Remove the item from the list
            } else {
              // Check children if present
              if (data.children && data.children.length > 0) {
                data.children = data.children.filter((ce) => {
                  const childIndex = res[0]["accesss"].findIndex((item) =>
                    this.compareObjects(ce, item)
                  );
                  return (
                    childIndex !== -1 &&
                    (res[0]["accesss"][childIndex].read ||
                      res[0]["accesss"][childIndex].write)
                  );
                });
              }
              return true; // Keep the item in the list
            }
          });
          this.userFeedback.stopLoading();
        },
        error: (err) => {
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      this.userFeedback.stopLoading();
    }
  }

  back() {
    this.location.back();
  }

  iconClick(item) {
    this.listMenu.forEach((element) => {
      if (element.iconName == item.iconName) {
        if (item.displayName == "Service Management") {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/services/service"]);
            });
        } else {
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   this.router.navigate([element.route]);
          // });
          this.router
            .navigate([element.route])
            .then((success) => { })
            .catch((err) => { });
        }
      }
    });
  }

  closeClick(eve) {
    this.getFeatureAccess();
    this.getUserDetails();
    this.isExpanded = eve;
    this.sideNavToggleValue.next(eve);
  }

  planNav() {
    this.getFeatureAccess();
    this.router.navigate(["/partner-subscription/plan-details"]);
  }

  billigNav() {
    this.getFeatureAccess();
    this.router.navigate(["/partner-subscription/billing-details"]);
  }

  viewPlan() {
    this.getFeatureAccess();
    this.router.navigate(["/partner-subscription/view-plan"]);
  }

  payNav() {
    try {
      let id = {
        clientId: this.partnerId,
      };
      this.subscriptionFacade.getSubDetailByCli(id).subscribe({
        next: (response) => {
          let object = {
            "planId": response.planId,
            "amount": response.planAmount,
            "planName": response.planName,
            "pricingId": response.pricingId,
          }
          let planDetails = this.encryptDecrypt.encryptData(object);
          //navigate to the payment page
          this.router.navigate(["/partner-subscription/payment"], {
            queryParams: { "planDetail": planDetails },
          }).then(() => {
            // Refresh the page after navigation
            window.location.reload();
          });
        },
        error: (error) => {
          //his.snackBar.errorSnackbar("Something went wrong, please try again");
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      //this.snackBar.errorSnackbar("Something went wrong, please try again");
      this.userFeedback.stopLoading();
    }
  }

}
