import { Component, Inject, OnInit } from '@angular/core';
import { AuthFacadeService } from 'src/app/facades/auth/auth-facade.service';
import { Router } from '@angular/router';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/core/store/permission/permission';
import { OnboardingFacadeService } from 'src/app/facades/onboarding/onboarding-facade.service';
import { UserFeedbackState } from 'src/app/core/utilities/user-feedback-state/user-feedback-state';
import { ConfirmOnboardService } from 'src/app/core/services/confirm-onboard/confirm-onboard.service';
import { StaffFacadesService } from 'src/app/facades/staff/staff-facades.service';
import { FeedbackSnackbarService } from "src/app/core/utilities/feedback-snackbar/feedback-snackbar.service";
import { SubscriptionFacadesService } from 'src/app/facades/subscription/subscription-facades.service';
import { StorageService } from 'src/app/core/store/storage/storage.service';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  value: any;
  stylistId: any;
  userFeedback = new UserFeedbackState();
  clientId: any;
  roleData: any;
  locationId: any;
  url: string;
  cliId: any;
  busiName: string;
  pmId: any;
  create: any;
  editPlan: any;
  currentPlan: any;
  checkPublish: boolean;
  partnerCount: any;

  constructor(private authFacadeService: AuthFacadeService,
    private router: Router,
    private conOnboard: ConfirmOnboardService,
    private onboardFacade: OnboardingFacadeService,
    private staffFacadeService: StaffFacadesService,
    private bottomSheet: MatBottomSheetRef<LogoutComponent>,
    private snackBar: FeedbackSnackbarService,
    private subscriptionFacade: SubscriptionFacadesService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private permission: Permission,
    private subscriptionFacadeService: SubscriptionFacadesService,
    private bottom: MatBottomSheet,
    private storageService: StorageService
  ) {
    const retrievedUserData = this.storageService.getUserData();
    if (retrievedUserData) {
      this.cliId = retrievedUserData.clientId;
    }
    this.busiName = data?.name;
    this.value = data?.value;
    this.stylistId = data?.id;
    this.clientId = data?.cliId;
    this.roleData = data?.updateRole;
    this.locationId = data?.locId;
    this.pmId = data?.pmId;
    this.create = data?.create;
    this.editPlan = data?.edit;
    this.currentPlan = data?.currentPlan;
    this.partnerCount = data?.partnerCount;
    this.url = environment.onboardingUrl + "?id=" + this.cliId + "&name=" + this.busiName;
  }
  /**
   * for dialog popup
   */
  // @Inject(MAT_DIALOG_DATA) data: { message: string },
  // public dialogRef: MatDialogRef<LogoutComponent>
  ngOnInit(): void {
  }

  // logout
  logout() {
    this.authFacadeService.signOut().then((res: any) => {
      localStorage.clear();
      this.permission.clearPermission();
      const domain = environment.domain;
      const pastDate = new Date(0).toUTCString();
      document.cookie = `myObjectCookie=; domain=${domain}; path=/; expires=${pastDate}`;
      this.router.navigate(['auth/login']);
    });
    this.bottomSheet.dismiss();
  }

  close() {
    this.bottomSheet.dismiss();
  }

  singleApprove() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": 0,
      "stylistId": this.stylistId,
      "locationId": 0,
      "registerStatus": true,
      "recordStatus": true
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  singleReject() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": 0,
      "locationId": 0,
      "stylistId": this.stylistId,
      "registerStatus": false,
      "recordStatus": false
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  approveAll() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": this.clientId || this.cliId,
      "locationId": this.locationId ? this.locationId : 0,
      "stylistId": 0,
      "registerStatus": true,
      "recordStatus": true
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  rejectAll() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": this.clientId || this.cliId,
      "locationId": this.locationId ? this.locationId : 0,
      "stylistId": 0,
      "registerStatus": false,
      "recordStatus": false
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  updateRole() {
    this.userFeedback.startLoading();
    this.staffFacadeService.updateRole(this.roleData).subscribe({
      next: (res) => {
        this.userFeedback.stopLoading();
        this.router.navigate(['/settings/staff-role']);
        this.bottomSheet.dismiss();
      }, error(err) {

      },
    })
  }

  copyUrl() {
    navigator.clipboard.writeText(this.url).then(() => {
      this.snackBar.successSnackbar("Stylist onboarding link copied to clipboard!");
    }, (err) => {
    });
  }

  deleteCard() {
    this.userFeedback.startLoading();
    let data = {
      "pmId": this.pmId
    }
    this.subscriptionFacade.deleteCard(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }
  //for success
  showSuccess() {
    this.snackBar.successSnackbar('Plan details are created successfully');
  }
  createPlan() {
    this.userFeedback.startLoading();
    this.subscriptionFacadeService.createPlan(this.create).subscribe({
      next: (response: any) => {
        if (response == 'Plan created successfully') {
          this.userFeedback.stopLoading();
          this.router.navigate(['/subscription/plans']);
          this.showSuccess();
          this.bottomSheet.dismiss();
        } else {
          this.userFeedback.stopLoading();
          this.snackBar.errorSnackbar(response);
        }
      },
      error: (error) => {
        // this.showError(e);
      }
    })
  }
  updatePlan(val: any) {
    this.userFeedback.startLoading();
    if (val == true) {
      this.editPlan.replacePublishPlan = true;
      this.editPlan.published = true;
      this.updatePlanApiCall();
    } else if (val == null) {

      this.publishConfirm();
    } else {
      this.editPlan.published = false;
      this.updatePlanApiCall();
    }

  }
  updatePlanApiCall() {
    try {
      this.subscriptionFacadeService.updatePlan(this.editPlan).subscribe({
        next: (response: any) => {
          if (response == "Plan updated successfully") {
            this.userFeedback.stopLoading();
            this.router.navigate(['/subscription/plans']);
            this.snackBar.successSnackbar('Plan details are updated successfully');
            this.bottomSheet.dismiss();
          }
          else {
            this.userFeedback.stopLoading();
            this.snackBar.errorSnackbar(response);
          }
        },
        error(e) {
          this.userFeedback.stopLoading();
          this.showError(e);
        }
      })
    } catch (error) {
      this.userFeedback.stopLoading();
    }

  }

  openConfirmationDialog(val: any): Promise<boolean> {
    return new Promise((resolve) => {
      const config: MatBottomSheetConfig = {
        hasBackdrop: true, // Enable backdrop
      };

      const bottomSheetRef: MatBottomSheetRef = this.bottom.open(LogoutComponent, {
        panelClass: 'custom-style',
        data: {
          "value": val,
          "edit": this.editPlan,
          "currentPlan": this.checkPublish ? this.currentPlan : ""
        },
        ...config,
      });

      bottomSheetRef.afterOpened().subscribe(() => {
        // Listen for backdrop click event and close the bottom sheet
        const backdrop = document.querySelector('.cdk-overlay-backdrop');
        if (backdrop) {
          backdrop.addEventListener('click', () => {
            bottomSheetRef.dismiss();
          });
        }
      });
    });
  }


  publishConfirm() {
    // this.userFeedback.startLoading();
    let data = {
      "planId": this.editPlan.id,
      "isPublished": true,
      "isChangeable": this.editPlan.isChangeable
    }
    this.subscriptionFacadeService.publishConfirm(data).subscribe({
      next: (response: any) => {
        if (response != null) {
          this.currentPlan = response;
          this.checkPublish = true;
          const confirmPublish = this.openConfirmationDialog('publishPlan');
          if (!confirmPublish) {
            return; // Exit if the user selected "No"
          }
        } else {
          this.updatePlanApiCall();
        }
        this.userFeedback.stopLoading();
      },
      error(e) {
        this.showError(e);
      }
    })
  }
}
