import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainLayoutContainerModule } from './view/main-layout-container/main-layout-container.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth/auth.interceptor';
import { MapperModule } from '@dynamic-mapper/angular';
import { MAPPING_PROFILES } from './core/mappings/mapping-profiles';
import { provideFirebaseApp, initializeApp, } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { AngularFireModule, } from '@angular/fire/compat';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconModule } from './icon.module';
import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NavService } from './core/utilities/navigation/nav.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PreloadService } from './core/store/storage/preload.service';
import { HandleGlobalErrorService } from './core/services/global-error/handle-global-error.service.spec';
export function preloadAppData(PreloadService: PreloadService): () => Promise<void> {
  return () => PreloadService.preloadData();
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MainLayoutContainerModule,
    HttpClientModule,
    MapperModule.withProfiles([...MAPPING_PROFILES]),
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    IconModule,
    MatSnackBarModule,
    PlatformModule,
    ScrollingModule

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: HTTP_INTERCEPTORS,
      // useClass: AuthHttpInterceptor,
      useFactory: function () {
        return new AuthInterceptor();
      },
      multi: true,
      deps: []
    },
    {
      provide: ScrollingModule,
      useValue: {
        momentum: false,
      },
    }, {
      provide: NavService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadAppData,
      deps: [PreloadService],
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: HandleGlobalErrorService // Add Global Error Handler here  
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
