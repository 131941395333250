<header>
    <!-- <div class="logo">
        <img *ngIf="darkMode" src="/assets/images/mvmLogoDark.svg">
        <img *ngIf="!darkMode" src="/assets/images/mvmLogo.svg">
    </div> -->
    <div class="logo-menu"><img src="/assets/images/Mvm2Fav.png" alt="" class="logo-img"><p class="logo-title">VISITMAKER</p></div>
</header>

<!-- <span class="settings-back"> -->

<!-- </span> -->
<div class="page-title">
    <!-- <mat-icon svgIcon="arrow-back" class="back-icon" (click)="back()"></mat-icon> -->
    <div class="sub-title">
        <h1>Privacy Policy</h1>
    </div>
</div>
<div class="content-align">
    <h2>1. Introduction</h2>
    <p>Welcome to the VISITMAKER. This Privacy Policy is designed to help you understand how we collect, use,
        disclose, and safeguard your personal information when you use our services.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information when you use our App:</p>
    <ul>
        <li>Personal Information:
            <ul>
                <li>Your name</li>
                <li>Contact information (email address, phone number)</li>
                <li>Salon and stylist preferences</li>
                <li>Billing and payment information</li>
                <li>Booking history</li>
                <li>Profile picture (if you choose to upload one)</li>
            </ul>
        </li>
        <li>Usage Data:
            <ul>
                <li>We may collect information about how you use the App, including:</li>
                <li>Dates and times of bookings</li>
                <li>Interaction with the App, such as pages viewed and features used</li>
                <li>Device information, including your device type, operating system, and browser type</li>
            </ul>
        </li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use your information for the following purposes:</p>
    <ul>
        <li>To provide and maintain the App's functionality.</li>
        <li>To process and manage salon bookings and payments.</li>
        <li>To personalize your user experience and improve our services.</li>
        <li>To communicate with you regarding your bookings and updates.</li>
        <li>To respond to your inquiries and provide customer support.</li>
        <li>To comply with legal and regulatory requirements.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>We may share your information with the following third parties:</p>
    <ul>
        <li>Salon owners and stylists to facilitate bookings and appointments.</li>
        <li>Payment processors for processing payments.</li>
        <li>Service providers who assist us in app development and maintenance.</li>
        <li>Legal and regulatory authorities when required by law or to protect our rights.</li>
    </ul>

    <h2>5. Data Security</h2>
    <p>We employ industry-standard security measures to protect your data. However, no method of transmission over the
        internet or electronic storage is entirely secure. We cannot guarantee the absolute security of your
        information.</p>

    <h2>6. Your Choices</h2>
    <p>You can:</p>
    <ul>
        <li>Update your personal information in your account settings.</li>
        <li>Opt-out of promotional communications.</li>
        <li>Delete your account by contacting us at <a
                href="mailto:support@visitmaker.com">support@visitmaker.com</a></li>
    </ul>

    <h2>7. Cookies and Tracking Technologies</h2>
    <p>We use cookies and similar tracking technologies to enhance your user experience. You can manage your cookie
        preferences through your browser settings.</p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy to reflect changes in our practices or for legal reasons. We will notify you of
        any material changes through the App or via email.</p>

    <h2>9. Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
    <p><a href="mailto:support@visitmaker.com">support@visitmaker.com</a></p>

    <h2>10. Consent</h2>
    <p>By using the Salon Stylist Booking Web App, you consent to the collection and use of your information as outlined
        in this Privacy Policy.</p>
</div>
<!-- <hr> -->
<footer>

</footer>