import { Injectable } from '@angular/core';
import { SubscriptionFacadesService } from 'src/app/facades/subscription/subscription-facades.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {
  typeId: number;
  partnerId: number;
  clientStaffId: number;

  constructor(
    private storageService: StorageService,
    private subscriptionFacade: SubscriptionFacadesService
  ) { 
  }

  preloadData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        const retrieveData = this.storageService.getUserData();
        if(retrieveData){
          this.typeId = retrieveData.typeId;
          this.partnerId = retrieveData.clientId;
          this.clientStaffId = retrieveData.clientStaffId;
          let apiCall = false;
          let clientId = 0;
          if(this.typeId !=2){
            if(this.partnerId != 0){
              clientId = this.partnerId;
              apiCall = true;
            }
          }else{
            if(this.clientStaffId != 0){
              clientId = this.clientStaffId;
              apiCall = true;
            }
          }
          if(apiCall == true){
              const data = { clientId: retrieveData.clientId };
              this.subscriptionFacade.getFeatureAccess(data).subscribe({
                next: (res: any) => {
                  // Update storage with the retrieved data
                  const updatedData = {
                    ...retrieveData,
                    "isCalendarAccess": res.isCalendarAccess,
                    "isDoubleBookingAccess": res.isDoubleBookingAccess,
                    "isRecurringAccess": res.isRecurringAccess,
                    "isReportsAccess": res.isReportsAccess,
                    "isEmailNotification": res.isEmailNotification,
                    "isSMSNotification": res.isReportsAccess,
                    "locationLimitReached": res.locationLimitReached,
                    "notesAccess": res.notesAccess,
                    "onlineBookAccess": res.onlineBookAccess,
                    "subscriptionExpired": res.subscriptionExpired,
                  };
                  this.storageService.setUserData(updatedData);
                  resolve(); // Resolve the promise once data is updated
                },
                error: (err) => {
                  console.error('Error loading feature access:', err);
                  resolve(); // Prevent blocking on error
                },
              });
         
          }else{
            resolve()
          }
        }else{
          resolve();
        }
      } catch (error) {
        console.error('Error in data preloading:', error);
        resolve(); // Prevent blocking on any unexpected error
      }
    });
  }
}
