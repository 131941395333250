import { Injectable } from '@angular/core';
import { SubscriptionFacadesService } from 'src/app/facades/subscription/subscription-facades.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {

  constructor(
    private storageService: StorageService,
    private subscriptionFacade: SubscriptionFacadesService
  ) { }

  preloadData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        const retrieveData = this.storageService.getUserData();
        
        if (retrieveData) {
          const data = { clientId: retrieveData.clientId };
          this.subscriptionFacade.getFeatureAccess(data).subscribe({
            next: (res: any) => {
              // Update storage with the retrieved data

              const updatedData = {
                ...retrieveData,
                "isCalendarAccess": res.isCalendarAccess,
                "isDoubleBookingAccess": res.isDoubleBookingAccess,
                "isRecurringAccess": res.isRecurringAccess,
                "isReportsAccess": res.isReportsAccess,
                "isEmailNotification": res.isEmailNotification,
                "isSMSNotification": res.isReportsAccess,
                "locationLimitReached": res.locationLimitReached,
                "notesAccess": res.notesAccess,
                "onlineBookAccess": res.onlineBookAccess,
                "subscriptionExpired": res.subscriptionExpired,
              };
              this.storageService.setUserData(updatedData);
              resolve(); // Resolve the promise once data is updated
            },
            error: (err) => {
              console.error('Error loading feature access:', err);
              resolve(); // Prevent blocking on error
            },
          });
        } else {
          resolve(); // No user data, resolve immediately
        }
      } catch (error) {
        console.error('Error in data preloading:', error);
        resolve(); // Prevent blocking on any unexpected error
      }
    });
  }
}
