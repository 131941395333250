import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { StaffFacadesService } from "src/app/facades/staff/staff-facades.service";
import { UserFacadeService } from "src/app/facades/user/user-facade.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivateChild {
  routesMap: any;
  pageName: any;
  typeId: any;
  constructor(
    private router: Router,
    private staffFacade: StaffFacadesService,
    private userFacade: UserFacadeService,
  ) {
    // const retrievedUserData = this.storageService.getUserData();
    // this.typeId = retrievedUserData.typeId;
    this.routesMap = {
      "dashboard": "Dashboard",
      "partners": "Partner Management",
      "locations": "Location Management",
      "onboarding": "Onboarding Approval",
      "stylists": "Stylist Management",
      // "services": "",
      "service-category": "Service Categories",
      "add-category": "Service Categories",
      "edit-category": "Service Categories",
      "service-name": "Service",
      "add-service-name": "Service",
      "edit-service-name": "Service",
      "service": "Stylist's Services",
      "add-service": "Stylist's Services",
      "edit-service": "Stylist's Services",
      "appointments": "Appointment Management",
      "customers": "Customer Management",
      "staff": "Staff",
      "add-edit-staff": "Staff",
      "staff-role": "Staff Roles",
      "add-edit-role": "Staff Roles",
      "settings": "Settings",
      "profile": "Profile",
      "business-info": "Settings",
      "subscription": "Subscription Management",
      "features": "Features",
      "plans": "Plans",
      "offers": "Offers",
      "reports":"Reports",
      "smsreport":"SMS Report",
      "partner-subscription": "My Subscription",
      "plan-details": " My Plan",
      "billing-details": "Billing",
      "view-plan": "Subscribe & Upgrade",
      //"payment": "Subscribe Plan"

    };
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve) => {
      const path = state.url.split('/');      
      let subPath = 'nopath';
      if (path[1] !== undefined || path[1] !== '' || path[1] !== null) {
        if (path[1] == 'services' || path[1] == 'settings') {
          subPath = path[2] ? ((path[2].includes('?')) ? path[2].substring(0, path[2].indexOf('?')) : path[2]) : path[1];
        }
        else {
          subPath = (path[1].includes('?')) ? path[1].substring(0, path[1].indexOf('?')) : path[1];          
        }
      }
      this.userFacade.setMenuByRoleAccess().subscribe({
        next: (response) => {
          let hasPermission = this.validatePermission(response, subPath);
          resolve(hasPermission);
        },
        error(err) {
          resolve(false);
        },
      });
    });
  }

  validatePermission(response, path) {  
    const permissionData: Array<any> = response[0]["accesss"];
    let status = false;
    permissionData.forEach((value, index) => {
      if (value && value.screenName === this.routesMap[path]) {
        this.pageName = this.routesMap[path];
        status = value.read;
      }
    });
    if (!status) {
      let data = true;
      let valData = permissionData.filter((x) => x.read === data);
      //path = this.typeId == 4 ? path == 'partners' ? 'locations' : path : path;
      if (valData[0].screenName == 'Dashboard') {
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([path]);
        // });
        this.router.navigateByUrl('/dashboard');
      } 
      else if (valData[0].screenName == 'Partner Management') {
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([path]);
        // });
        this.router.navigateByUrl('/partners');
      } else if(valData[0].screenName == 'Location Management'){
        this.router.navigateByUrl('/locations');
      }else if(valData[0].screenName == 'Onboarding Approval'){
        this.router.navigateByUrl('/onboarding');
      }else if(valData[0].screenName == 'Stylist Management'){
        this.router.navigateByUrl('/stylists');
      }else if(valData[0].screenName == 'Service Categories'){
        this.router.navigateByUrl('/services/service-category');
      }else if(valData[0].screenName == 'Service'){
        this.router.navigateByUrl('/services/service-name');
      }else if(valData[0].screenName == "Stylist's Services"){
        this.router.navigateByUrl('/services/service');
      }else if(valData[0].screenName == "Appointment Management"){
        this.router.navigateByUrl('/appointments');
      }else if(valData[0].screenName == "Customer Management"){
        this.router.navigateByUrl('/customers');
      }else if(valData[0].screenName == "Staff"){
        this.router.navigateByUrl('/settings/staff');
      }else if(valData[0].screenName == "Staff Roles"){
        this.router.navigateByUrl('/settings/staff-role');
      }else if(valData[0].screenName == "Profile"){
        this.router.navigateByUrl('/settings');
      }else if(valData[0].screenName == "Settings"){
        this.router.navigateByUrl('/settings');
      }
      else if(valData[0].screenName == "SubscriptionManagement"){
        this.router.navigateByUrl('/subscription');
      }
      else if(valData[0].screenName == "Features"){
        this.router.navigateByUrl('/subscription/features');
      }else if(valData[0].screenName == "Plans"){
        this.router.navigateByUrl('/subscription/plans');
      }else if(valData[0].screenName == "Offers"){
        this.router.navigateByUrl('/subscription/offers');
      }
      else if(valData[0].screenName == "Reports"){
        this.router.navigateByUrl('/reports');
      }
      else if(valData[0].screenName == "SMS Report"){
        this.router.navigateByUrl('/reports');
      }
      else {      
        return false;
      }
    }
    return status;
  }
}
